
import { mapActions, mapGetters } from 'vuex';
import UICreateComment from '@/components/ui/organism/UICreateComment';
import {
  uploadPostImage,
  searchMentions,
  searchHashtags,
} from '@/utils/mixins';

export default {
  name: 'CreateCommentWrapper',
  components: { UICreateComment },
  mixins: [uploadPostImage, searchMentions, searchHashtags],
  props: {
    target: {
      type: String,
      required: true,
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
    targetType: {
      type: String,
      required: false,
      default: 'POST',
    },
    showProfileImage: {
      type: Boolean,
      default: true,
    },
    topMargin: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      clear: false,
    };
  },
  computed: {
    ...mapGetters('authentication', ['isEmailConfirmed', 'profile']),
    ...mapGetters('api', ['createPostRes']),
  },
  watch: {
    clear() {
      if (this.clear) {
        setTimeout(() => {
          this.clear = false;
        }, 100);
      }
    },
    'createPostRes.data'() {
      if (this.createPostRes.data) {
        this.clear = true;
      }
    },
  },

  methods: {
    ...mapActions('modals', ['showMentionCard', 'hideMentionCard']),
    ...mapActions('api', ['createPost']),
    ...mapActions({
      appendToHomeFeed: 'home/appendNewPostAtTop',
      appendToProfileFeed: 'profile/appendNewPostAtTop',
    }),
    onSubmit(data) {
      this.$emit('onSubmit', data);
    },
  },
};
