import { showErrorToast } from '~/utils/toast-notifications';

export default {
  /// Symbols
  SYMBOLS_INIT: (state) => {
    state.companySymbols.data = [];
    state.companySymbols.error = null;
    state.companySymbols.loading = true;
  },
  SET_COMPANY_SYMBOLS_TICKERS: (state, payload) => {
    state.companySymbols.data = payload;
  },

  SET_COMPANY_SYMBOLS_ERROR: (state, er) => {
    state.companySymbols.error = er;
  },

  SET_COMPANY_SYMBOLS_LOADING: (state, loading) => {
    state.companySymbols.loading = loading;
  },

  /// Headlines
  HEADLINES_INIT: (state) => {
    state.headlines.data = [];
    state.headlines.error = null;
    state.headlines.loading = true;
  },

  SET_COMPANY_HEADLINES: (state, payload) => {
    state.headlines.data = payload;
  },

  SET_COMPANY_HEADLINES_ERROR: (state, er) => {
    state.headlines.error = er;
  },

  SET_COMPANY_HEADLINES_LOADING: (state, loading) => {
    state.headlines.loading = loading;
  },

  APPEND_COMPANY_HEADLINES: (state, data) => {
    state.nextHeadlines.data = data;
    state.headlines.data = [...state.headlines.data, ...data];
  },

  APPEND_COMPANY_HEADLINES_ERROR: (state, error) => {
    state.nextHeadlines.error = error;
  },

  APPEND_COMPANY_HEADLINES_LOADING: (state, loading) => {
    state.nextHeadlines.loading = loading;
  },

  /// Symbols to compare
  SYMBOLS_TO_COMPARE_INIT: (state) => {
    state.tickersToCompare.data = [];
    state.tickersToCompare.error = null;
    state.tickersToCompare.loading = true;
  },

  SET_TICKERS_TO_COMPARE: (state, payload) => {
    const ticker = payload.data.find(
      (item) => item.name === payload.companyName
    );

    if (ticker) {
      if (state.tickersToCompare.data === null) {
        state.tickersToCompare.data = [];
      }
      const tickerExists = state.tickersToCompare.data.find(
        (item) => item.name === payload.companyName
      );
      if (tickerExists === undefined) {
        state.tickersToCompare.data.push(ticker);
      }
    }
  },

  SET_MULTIPLE_TICKERS_TO_COMPARE_SUCCESS: (state, payload) => {
    if (state.tickersToCompare.data === null) {
      state.tickersToCompare.data = [];
    }

    payload.forEach((symbol) => {
      state.tickersToCompare.data.push(symbol);
    });
  },

  SET_MULTIPLE_TICKERS_TO_COMPARE_ERROR: (state, error) => {
    state.tickersToCompare.error = error;
    state.tickersToCompare.loading = false;
  },

  REMOVE_TICKER: (state, payload) => {
    const indexToRemove = state.tickersToCompare.data.findIndex(
      (item) => item.name === payload
    );

    if (indexToRemove !== -1) {
      state.tickersToCompare.data.splice(indexToRemove, 1);
    }
  },

  REMOVE_ALL_TICKERS: (state) => {
    state.tickersToCompare.data = [];
  },

  SET_TICKERS_TO_COMPARE_ERROR: (state, er) => {
    state.tickersToCompare.error = er;
  },

  SET_TICKERS_TO_COMPARE_LOADING: (state, loading) => {
    state.tickersToCompare.loading = loading;
  },

  RESET_TICKERS_TO_COMPARE: (state) => {
    state.tickersToCompare.data = null;
    state.tickersToCompare.error = null;
    state.tickersToCompare.loading = false;
  },

  // Fundamentals
  GET_FUNDAMENTALS_INIT: (state) => {
    state.primaryTickerFundamentals.hasPrimaryTickerFundamentals = true;
    state.primaryTickerFundamentals.data = null;
    state.primaryTickerFundamentals.loading = true;
    state.primaryTickerFundamentals.error = null;
  },

  SET_FUNDAMENTALS_SUCCESS: (state, payload) => {
    state.primaryTickerFundamentals.hasPrimaryTickerFundamentals = true;
    state.primaryTickerFundamentals.data = payload.data;
  },

  SET_FUNDAMENTALS_ERROR: (state, er) => {
    console.info(er);
    state.primaryTickerFundamentals.hasPrimaryTickerFundamentals = false;
    state.primaryTickerFundamentals.error = er;
  },

  SET_FUNDAMENTALS_LOADING: (state, isLoading) => {
    state.primaryTickerFundamentals.loading = isLoading;
  },

  GET_TICKER_FUNDAMENTALS_INIT: (state) => {
    state.tickerFundamentals.data = null;
    state.tickerFundamentals.error = null;
    state.tickerFundamentals.loading = true;
  },

  SET_TICKER_FUNDAMENTALS_SUCCESS: (state, data) => {
    state.tickerFundamentals.data = data;
  },

  SET_TICKER_FUNDAMENTALS_ERROR: (state, err) => {
    state.tickerFundamentals.error = err;
  },

  SET_TICKER_FUNDAMENTALS_LOADING: (state, loading) => {
    state.tickerFundamentals.loading = loading;
  },

  // Follow Symbol Price Change
  FOLLOW_SYMBOL_STOCK_PRICE_CHANGE_SUCCESS: (state, payload) => {
    if (state.companySymbols.data) {
      state.currentlyActiveTicker.isFollowingPriceChange =
        payload.receiveNotifications;
    }
  },

  SET_CURRENTLY_ACTIVE_TICKER: (state, ticker) => {
    state.currentlyActiveTicker = ticker;
  },
  SET_CURRENTLY_ACTIVE_TICKER_INDEX: (state, tickerIndex) => {
    state.currentlyActiveTickerIndex = tickerIndex;
  },

  SET_COMPANY_PORTFOLIOS_LOADING: (state, value) => {
    state.companyPortfolios.loading = value;
  },

  SET_NEXT_COMPANY_PORTFOLIOS_LOADING: (state, value) => {
    state.nextCompanyPortfolios.loading = value;
  },

  GET_COMPANY_PORTFOLIOS_SUCCESS: (state, data) => {
    state.companyPortfolios.data = data.data;
  },

  GET_COMPANY_PORTFOLIOS_ERROR: (state, error) => {
    state.companyPortfolios.error = error;
  },

  GET_NEXT_COMPANY_PORTFOLIOS_SUCCESS: (state, data) => {
    state.companyPortfolios.data = [
      ...state.companyPortfolios.data,
      ...data.data,
    ];
  },

  GET_NEXT_COMPANY_PORTFOLIOS_ERROR: (state, error) => {
    state.companyPortfolios.error = error;
  },

  GET_COMPANY_COMMENTS_INIT: (state) => {
    state.companyProfileComments = { data: null, loading: true, error: null };
  },
  SET_COMPANY_COMMENTS_LOADING: (state, value) => {
    state.companyProfileComments.loading = value;
  },

  SET_COMPANY_COMMENTS: (state, data) => {
    state.companyProfileComments.data = data;
  },

  SET_NEXT_COMMENTS_LOADING: (state, value) => {
    state.nextCommentsLoading = value;
  },

  APPEND_NEXT_COMPANY_COMMENTS: (state, data) => {
    state.companyProfileComments.data = [
      ...state.companyProfileComments.data,
      ...data,
    ];
  },

  SET_COMPANY_COMMENTS_ERROR: (state, error) => {
    state.companyProfileComments.error = error;
  },

  APPEND_NEW_COMMENT: (state, data) => {
    if (!state.companyProfileComments.data) {
      state.companyProfileComments.data = [];
    }
    state.companyProfileComments.data = [
      data,
      ...state.companyProfileComments.data,
    ];
  },

  SET_COMMENTS_ERROR: (state, error) => {
    state.companyProfileComments.error = error;
    showErrorToast(window.$nuxt.$t('error_post_page_replies'));
  },

  UPDATE_COMMENT: (state, data) => {
    if (state.companyProfileComments.data) {
      const index = state.companyProfileComments.data.findIndex(
        (c) => c.postId === data.postId
      );

      if (index !== -1) {
        const res = [...state.companyProfileComments.data];

        // Reply-related info are not sent back from the API
        const replies = res[index].replies;

        res[index] = data;
        res[index].replies = replies;

        state.companyProfileComments.data = res;
      }
    }
  },

  SET_REPLIES_LOADING: (state, value) => {
    state.repliesLoading = value;
  },

  SET_NESTED_REPLIES: (state, data) => {
    if (state.companyProfileComments.data) {
      const index = state.companyProfileComments.data.findIndex(
        (c) => c.postId === data.postId
      );
      if (index !== -1) {
        const res = [...state.companyProfileComments.data];
        res[index].replies = data.replies;
        state.companyProfileComments.data = res;
      }
    }
  },

  APPEND_NESTED_REPLIES: (state, payload) => {
    if (state.companyProfileComments.data) {
      const index = state.companyProfileComments.data.findIndex(
        (c) => c.postId === payload.postId
      );

      if (index !== -1) {
        const res = [...state.companyProfileComments.data];

        res[index].replies = [...res[index].replies, ...payload.replies];
        state.companyProfileComments.data = res;
      }
    }
  },

  INCREMENT_COMMENT_REPOST_COUNT: (state, postId) => {
    if (state.companyProfileComments.data) {
      const index = state.companyProfileComments.data.findIndex(
        (c) => c.postId === postId
      );

      if (index !== -1) {
        state.companyProfileComments.data[index].repostCount++;
      }
    }
  },

  UPDATE_NESTED_COMMENT: (state, payload) => {
    if (state.companyProfileComments.data) {
      const commentIndex = state.companyProfileComments.data.findIndex(
        (c) => c.postId === payload.replyId
      );

      const nestedCommentIndex = state.companyProfileComments.data[
        commentIndex
      ].replies.findIndex((r) => r.postId === payload.nestedReply.postId);

      if (commentIndex !== -1 && nestedCommentIndex !== -1) {
        const replies = [...state.companyProfileComments.data];
        const nestedComments = [...replies[commentIndex].replies];

        nestedComments[nestedCommentIndex] = payload.nestedReply;
        replies[commentIndex].replies = nestedComments;

        state.companyProfileComments.data = replies;
      }
    }
  },

  DELETE_COMPANY_COMMENT_SUCCESS: (state, postId) => {
    if (state.companyProfileComments.data) {
      const commentIndex = state.companyProfileComments.data.findIndex(
        (e) => e.postId === postId
      );

      if (commentIndex !== -1) {
        state.companyProfileComments.data.splice(commentIndex, 1);
      }
    }
  },

  DELETE_COMPANY_COMMENT_ERROR: (state, error) => {
    state.deleteCompanyComment = error;
  },

  DELETE_NESTED_COMPANY_COMMENT_SUCCESS: (state, payload) => {
    if (state.companyProfileComments.data) {
      const postIndex = state.companyProfileComments.data.findIndex(
        (r) => r.postId === payload.postId
      );

      if (postIndex !== -1) {
        const nestedReplyIndex = state.companyProfileComments.data[
          postIndex
        ].replies.findIndex((nr) => nr.postId === payload.nestedReplyId);

        if (nestedReplyIndex !== -1) {
          // Decrementing reply count for the post
          state.companyProfileComments.data[postIndex].replyCount--;
          state.companyProfileComments.data[postIndex].replies.splice(
            nestedReplyIndex,
            1
          );
        }
      }
    }
  },
};
