
import CustomModal from '~/components/ui/molecule/CustomModal.vue';
import { mapActions, mapGetters } from 'vuex';
import CompanyTagCard from '~/components/ui/molecule/CompanyTagCard.vue';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import { buildAssetUri } from '~/utils/mixins';

export default {
  name: 'CompanyTickersListModal',
  components: {
    CustomModal,
    CompanyTagCard,
    CloseIcon,
  },
  mixins: [buildAssetUri],
  data() {
    return {
      // Used to trigger the animation in Custom Modal for company's tickers component
      showCompanyTickersListModal: false,
    };
  },
  computed: {
    ...mapGetters('companyProfile', [
      'getCompanySymbolsRes',
      'getCurrentlyActiveTickerIndex',
    ]),
    ...mapGetters('profile', ['profile']),
  },
  mounted() {
    this.showCompanyTickersListModal = true;
  },
  methods: {
    handleCloseModal() {
      this.showCompanyTickersListModal = false;
      this.$emit('closeModal');
    },
    ...mapActions('modals', ['toggleCompanyTickersListModal']),
    ...mapActions('companyProfile', [
      'setCurrentlyActiveTicker',
      'setActiveTickerIndex',
    ]),
    handleActiveTickerChange(index) {
      this.setActiveTickerIndex(index);
      const currentlyActiveTicker = this.getCompanySymbolsRes.data[index];
      this.setCurrentlyActiveTicker(currentlyActiveTicker);
      this.toggleCompanyTickersListModal(false);
    },
  },
};
