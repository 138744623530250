import { showErrorToast } from '~/utils/toast-notifications';

export default {
  INIT: (state) => {
    state.notifications = null;
    state.error = null;
    state.nextNotificationsError = null;
    state.loading = false;
    state.nextNotificationsLoading = false;
    state.notificationCount = 0;
    state.notificationOffset = 1;
  },
  SET_LOADING: (state, isLoading) => {
    state.loading = isLoading;
  },
  SET_ERROR: (state, err) => {
    state.error = err;
    showErrorToast(window.$nuxt.$t('error_loading_notifications'));
  },
  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload;
  },
  SET_NOTIFICATION_COUNT: (state, count) => {
    state.notificationCount = count;
  },
  APPEND_NEXT_NOTIFICATIONS: (state, payload) => {
    state.notifications = [...state.notifications, ...payload];
  },
  APPEND_NEW_NOTIFICATION: (state, payload) => {
    if (!state.notifications) {
      // Might be null, handle that case.
      state.notifications = [payload];
      state.notifications.count = 1;
      return;
    }

    const isSameNotification = (not) =>
      not.type === payload.type &&
      not.author.profileId === payload.author.profileId &&
      not.profileId === payload.profileId &&
      not.targetId === payload.targetId;

    const lastMatchedNotification =
      state.notifications.find(isSameNotification);
    const filteredNotifications = state.notifications.filter(
      (not) => !isSameNotification(not)
    );

    state.notifications = [payload, ...filteredNotifications];

    if (lastMatchedNotification && !lastMatchedNotification.read) {
      state.notificationCount--;
    }

    state.notificationCount++;
  },
  SET_NEXT_NOTIFICATIONS_LOADING: (state, isLoading) => {
    state.nextNotificationsLoading = isLoading;
  },
  SET_NEXT_NOTIFICATIONS_ERROR: (state, err) => {
    state.nextNotiicationsError = err;
    showErrorToast(window.$nuxt.$t('error_loading_more_notifications'));
  },
  MARK_AS_READ: (state, id) => {
    if (state.notifications) {
      const index = state.notifications.findIndex(
        (e) => e.notificationId === id
      );

      if (index !== -1) {
        state.notifications[index].read = true;
        state.notificationCount--;
      }
    }
  },
  MARK_AS_UNREAD: (state, id) => {
    if (state.notifications) {
      const index = state.notifications.findIndex(
        (e) => e.notificationId === id
      );

      if (index !== -1) {
        state.notifications[index].read = false;
        state.notificationCount++;
      }
    }
  },
  MARK_ALL_AS_READ: (state) => {
    if (state.notifications) {
      state.notifications.forEach((n) => (n.read = true));
      state.notificationCount = 0;
    }
  },
  MARK_MULTIPLE_AS_READ: (state, ids) => {
    if (state.notifications) {
      ids.forEach((id) => {
        const index = state.notifications.findIndex(
          (e) => e.notificationId === id
        );

        if (index !== -1) {
          state.notifications[index].read = true;
          state.notificationCount--;
        }
      });
    }
  },
  REMOVE_NOTIFICATION: (state, id) => {
    if (state.notifications) {
      const index = state.notifications.findIndex(
        (e) => e.notificationId === id
      );
      if (!state.notifications[index].read) {
        state.notificationCount--;
      }
      state.notifications.splice(index, 1);
    }
  },
  INCREASE_OFFSET: (state) => {
    state.notificationOffset++;
  },
  SET_HAS_MORE_NOTIFICATIONS: (state, value) => {
    state.hasMoreNotifications = value;
  },
  RESET_NOTIFICATIONS_OFFSET: (state) => {
    state.notificationOffset = 1;
  },
};
