import { showErrorToast, showToast } from '~/utils/toast-notifications';

export default {
  init(context) {
    context.commit('SYMBOLS_INIT');
    context.commit('HEADLINES_INIT');
    context.commit('TICKERS_INIT');
  },
  getCompanySymbols(context, payload) {
    const { noSkeletons, companyId } = payload ?? {};

    if (!noSkeletons) context.commit('SET_COMPANY_SYMBOLS_LOADING', true);

    this.dispatch('api/getCompanySymbols', companyId)
      .then((res) => {
        context.commit('SET_COMPANY_SYMBOLS_TICKERS', res.data);
      })
      .catch((err) => {
        context.commit('SET_COMPANY_SYMBOLS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_COMPANY_SYMBOLS_LOADING', false);
      });
  },
  getCompanyHeadlines(context, payload) {
    const { noSkeletons, profileId, limit, page } = payload ?? {};

    if (!noSkeletons) context.commit('SET_COMPANY_HEADLINES_LOADING', true);

    this.dispatch('api/getCompanyHeadlines', {
      profileId: profileId,
      limit: limit,
      page: page,
    })
      .then((res) => {
        context.commit('SET_COMPANY_HEADLINES', res.data);
      })
      .catch((err) => {
        context.commit('SET_COMPANY_HEADLINES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_COMPANY_HEADLINES_LOADING', false);
      });
  },
  getMoreCompanyHeadlines(context, payload) {
    context.commit('APPEND_COMPANY_HEADLINES_LOADING', true);
    return this.dispatch('api/getCompanyHeadlines', {
      profileId: payload.profileId,
      limit: payload.limit,
      page: payload.page,
    })
      .then((res) => {
        context.commit('APPEND_COMPANY_HEADLINES', res.data);
      })
      .catch((err) => {
        context.commit('APPEND_COMPANY_HEADLINES_ERROR', err);
      })
      .finally(() => {
        context.commit('APPEND_COMPANY_HEADLINES_LOADING', false);
      });
  },
  getTickersToCompare(context, payload) {
    context.commit('SET_TICKERS_TO_COMPARE_LOADING', true);
    this.dispatch('api/getCompanySymbols', payload.companyId)
      .then((res) => {
        context.commit('SET_TICKERS_TO_COMPARE', {
          data: res.data,
          companyName: payload.companyName,
        });
      })
      .catch((err) => {
        context.commit('SET_TICKERS_TO_COMPARE_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TICKERS_TO_COMPARE_LOADING', false);
      });
  },
  resetTickersToCompare(context) {
    context.commit('RESET_TICKERS_TO_COMPARE');
  },
  getMultipleTickersToCompare(context, payload) {
    context.commit('SET_TICKERS_TO_COMPARE_LOADING', true);
    this.dispatch('api/getMultipleCompanySymbols', payload)
      .then((res) => {
        context.commit('SET_MULTIPLE_TICKERS_TO_COMPARE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('SET_MULTIPLE_TICKERS_TO_COMPARE_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TICKERS_TO_COMPARE_LOADING', false);
      });
  },
  removeTicker(context, payload) {
    context.commit('REMOVE_TICKER', payload);
  },
  removeAllTickers(context) {
    context.commit('REMOVE_ALL_TICKERS');
  },
  clearTickersToCompare(context) {
    context.commit('SYMBOLS_TO_COMPARE_INIT');
  },
  getPrimaryTickerFundamentals(context, payload) {
    context.commit('GET_FUNDAMENTALS_INIT');
    this.dispatch('api/getPrimaryTickerFundamentals', payload.companyId)
      .then((res) => {
        context.commit('SET_FUNDAMENTALS_SUCCESS', {
          data: res.data,
          companyName: payload.companyName,
        });
      })
      .catch((err) => {
        context.commit('SET_FUNDAMENTALS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_FUNDAMENTALS_LOADING', false);
      });
  },
  getTickerFundamentals(context, payload) {
    const { noSkeletons, ticker } = payload ?? {};

    if (!noSkeletons) context.commit('SET_TICKER_FUNDAMENTALS_LOADING', true);

    return this.dispatch('api/getTickerFundamentals', ticker)
      .then((res) => {
        context.commit('SET_TICKER_FUNDAMENTALS_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TICKER_FUNDAMENTALS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TICKER_FUNDAMENTALS_LOADING', false);
      });
  },
  followSymbolStockPriceChange(context, payload) {
    this.dispatch('api/followStockChange', payload)
      .then(() => {
        context.commit('FOLLOW_SYMBOL_STOCK_PRICE_CHANGE_SUCCESS', payload);
        payload.receiveNotifications == true
          ? showToast(window.$nuxt.$t('price_change_alert_activated'))
          : showToast(window.$nuxt.$t('price_change_alert_deactivated'));
      })
      .catch((err) => {
        context.commit('SET_COMPANY_SYMBOLS_ERROR', err);
      });
  },

  setCurrentlyActiveTicker(context, ticker) {
    context.commit('SET_CURRENTLY_ACTIVE_TICKER', ticker);
  },
  setActiveTickerIndex(context, tickerIndex) {
    context.commit('SET_CURRENTLY_ACTIVE_TICKER_INDEX', tickerIndex);
  },

  getCompanyPortfolios(context, payload) {
    context.commit('SET_COMPANY_PORTFOLIOS_LOADING', true);
    return this.dispatch('api/getCompanyPortfolios', payload)
      .then((res) => {
        context.commit('GET_COMPANY_PORTFOLIOS_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_COMPANY_PORTFOLIOS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_COMPANY_PORTFOLIOS_LOADING', false);
      });
  },

  getNextCompanyPortfolios(context, payload) {
    context.commit('SET_NEXT_COMPANY_PORTFOLIOS_LOADING', true);
    return this.dispatch('api/getCompanyPortfolios', payload)
      .then((res) => {
        context.commit('GET_NEXT_COMPANY_PORTFOLIOS_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_NEXT_COMPANY_PORTFOLIOS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_COMPANY_PORTFOLIOS_LOADING', false);
      });
  },

  getCompanyProfileComments(context, payload) {
    context.commit('GET_COMPANY_COMMENTS_INIT', true);
    return this.dispatch('api/getCompanyProfileComments', payload)
      .then((res) => {
        context.commit('SET_COMPANY_COMMENTS', res.data);
      })
      .catch((err) => {
        context.commit('SET_COMPANY_COMMENTS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_COMPANY_COMMENTS_LOADING', false);
      });
  },

  getNextCompanyProfileComments(context, payload) {
    context.commit('SET_NEXT_COMMENTS_LOADING', true);
    return this.dispatch('api/getCompanyProfileComments', payload)
      .then((res) => {
        context.commit('APPEND_NEXT_COMPANY_COMMENTS', res.data);
      })
      .catch((err) => {
        context.commit('SET_COMPANY_COMMENTS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_COMMENTS_LOADING', false);
      });
  },

  postComment(context, payload) {
    return this.dispatch('api/createPost', payload)
      .then((res) => {
        showToast(window.$nuxt.$t('post_created_ok'));
        context.commit('APPEND_NEW_COMMENT', res.data);
      })
      .catch((err) => {
        showErrorToast(window.$nuxt.$t('error_occured_message'));
        context.commit('SET_COMMENTS_ERROR', err);
      });
  },

  likeComment(context, payload) {
    return this.dispatch('api/likePost', payload)
      .then((res) => {
        context.commit('UPDATE_COMMENT', res.data);
      })
      .catch((err) => {
        context.commit('SET_COMMENTS_ERROR', err);
      });
  },

  favoriteComment(context, payload) {
    return this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_COMMENT', res.data);
      })
      .catch((err) => {
        context.commit('SET_COMMENTS_ERROR', err);
      });
  },

  getNestedReplies(context, payload) {
    context.commit('SET_REPLIES_LOADING', true);
    return this.dispatch('api/getPostReplies', payload)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          context.commit('SET_NESTED_REPLIES', {
            postId: payload.postId,
            replies: res.data,
          });
        }
      })
      .catch((err) => {
        context.commit('SET_COMMENTS_ERROR', err);
      });
  },

  getNextNestedReplies(context, payload) {
    return this.dispatch('api/getPostReplies', payload)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          context.commit('APPEND_NESTED_REPLIES', {
            postId: payload.postId,
            replies: res.data,
          });
        }
      })
      .catch((err) => {
        context.commit('SET_COMMENTS_ERROR', err);
      });
  },

  incrementCommentRepostCount(context, payload) {
    context.commit('INCREMENT_COMMENT_REPOST_COUNT', payload.postId);
  },

  likeNestedComment(context, payload) {
    return this.dispatch('api/likePost', payload.nestedReplyId)
      .then((res) => {
        context.commit('UPDATE_NESTED_COMMENT', {
          replyId: payload.replyId,
          nestedReply: res.data,
        });
      })
      .catch((err) => {
        context.commit('SET_COMMENTS_ERROR', err);
      });
  },

  favoriteNestedComment(context, payload) {
    return this.dispatch('api/favoritePost', payload.nestedCommentId)
      .then((res) => {
        context.commit('UPDATE_NESTED_COMMENT', {
          replyId: payload.replyId,
          nestedReply: res.data,
        });
        showToast(window.$nuxt.$t('post_deletion_successful'));
      })
      .catch((err) => {
        context.commit('SET_COMMENTS_ERROR', err);
        showErrorToast(window.$nuxt.$t('error_occured_message'));
      });
  },
  deleteCompanyComment(context, payload) {
    return this.dispatch('api/deleteUserPost', payload)
      .then((res) => {
        context.commit('DELETE_COMPANY_COMMENT_SUCCESS', res.data);
        showToast(window.$nuxt.$t('post_deletion_successful'));
      })
      .catch((err) => {
        context.commit('DELETE_COMPANY_COMMENT_ERROR', err);
        showErrorToast(window.$nuxt.$t('error_occured_message'));
      });
  },
  deleteNestedCompanyComment(context, payload) {
    return this.dispatch('api/deleteUserPost', {
      postId: payload.nestedReplyId,
    })
      .then((res) => {
        context.commit('DELETE_NESTED_COMPANY_COMMENT_SUCCESS', {
          postId: payload.postId,
          nestedReplyId: res.data,
        });
        showToast(window.$nuxt.$t('post_deletion_successful'));
      })
      .catch((err) => {
        context.commit('DELETE_NESTED_COMPANY_COMMENT_ERROR', err);
        showErrorToast(window.$nuxt.$t('error_occured_message'));
      });
  },
};
