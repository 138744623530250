import { findAndReplace } from '@/utils';
import { showErrorToast } from '~/utils/toast-notifications';

export default {
  GET_HEADLINES_START(state) {
    state.headlinesLoading = true;
    state.headlinesError = null;
  },
  GET_HEADLINES_SUCCESS(state, payload) {
    state.headlines = payload;
    state.headlinesLoading = false;
  },
  GET_HEADLINES_ERROR(state, payload) {
    state.headlinesError = payload;
    state.headlinesLoading = false;
  },
  GET_FEED_START: (state) => {
    state.feed = null;
    state.newPostNumber = null;
    state.error = null;
    state.feedLoading = true;
  },
  GET_FEED_SUCCESS: (state, data) => {
    state.feed = data;
    state.feedLoading = false;
  },
  GET_FEED_ERROR: (state, error) => {
    state.error = error;
    state.feedLoading = false;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
    showErrorToast(window.$nuxt.$t('error_market_page'));
  },
  UPDATE_POST: (state, payload) => {
    state.feed = findAndReplace(state.feed, 'postId', payload.postId, payload);
  },
  INCREMENT_REPLYCOUNT: (state, postId) => {
    if (state.feed) {
      const index = state.feed.findIndex((e) => e.postId === postId);

      if (index != -1) {
        state.feed[index].replyCount++;
      }
    }
  },
  INCREMENT_REPOSTCOUNT: (state, postId) => {
    if (state.feed) {
      const index = state.feed.findIndex((e) => e.postId === postId);

      if (index != -1) {
        state.feed[index].repostCount++;
      }
    }
  },
  APPEND_POSTS_TO_FEED: (state, payload) => {
    state.feed = [...state.feed, ...payload];
  },
  APPEND_NEW_POST_AT_TOP: (state, post) => {
    if (state.feed) state.feed = [post, ...state.feed];
  },
  SET_FEED_LOADING: (state, isLoading) => {
    state.feedLoading = isLoading;
  },
  SET_NEXT_POSTS_LOADING: (state, isLoading) => {
    state.nextPostsLoading = isLoading;
  },
  // Replies
  SET_REPLIES_LOADING: (state, payload) => {
    const post = state.feed.find((e) => e.postId === payload.target);
    if (post) {
      post.repliesLoading = payload.isLoading;
    }
  },
  SET_REPLIES_ERROR: (state, payload) => {
    const post = state.feed.find((e) => e.postId === payload.target);
    if (post) {
      post.repliesError = payload.err;
      //TODO : add some toast
    }
  },
  APPEND_NEW_REPLY: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.target);
      if (post) {
        if (!post.replies) {
          post.replies = [];
        }
        const newArray = post.replies.slice(0, 1);
        post.replies = [payload, ...newArray];
        post.replyCount++;
      }
    }
  },
  UPDATE_REPLY: (state, data) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === data.target);
      if (post) {
        const index = post.replies.findIndex((e) => e.postId === data.postId);
        const res = [...post.replies];
        res[index] = data;

        post.replies = res;
      }
    }
  },
  INCREMENT_REPLY_REPLY_COUNT: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.postId);
      if (post) {
        const index = post.replies.findIndex(
          (e) => e.postId === payload.replyId
        );

        post.replies[index].replyCount++;
      }
    }
  },
  UPDATE_POST_LIKE: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        post.isLiked = payload.isLiked;
        post.likeCount = payload.likeCount;
      }
    }
  },
  UPDATE_POST_FAVORITE: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        post.isFavorited = payload.isFavorited;
      }
    }
  },
  /// Nested Replies
  SET_NESTED_REPLIES: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        const reply = post.replies.find((e) => e.postId === payload.replyId);

        if (reply) {
          reply.replies = payload.replies;
        }
      }
    }
  },
  APPEND_NESTED_REPLY_BOTTOM: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        const reply = post.replies.find((e) => e.postId === payload.replyId);

        if (reply) {
          reply.replies = [...reply.replies, ...payload.replies];
        }
      }
    }
  },
  APPEND_NESTED_REPLY_TOP: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        const reply = post.replies.find(
          (e) => e.postId === payload.data.target
        );

        if (reply) {
          if (!reply.replies) {
            reply.replies = [payload.data];
          } else {
            reply.replies = [payload.data, ...reply.replies];
          }
        }
      }
    }
  },
  UPDATE_NESTED_REPLY: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.postId);
      if (post) {
        const reply = post.replies.find(
          (e) => e.postId === payload.data.target
        );

        if (reply) {
          const index = reply.replies.findIndex(
            (e) => e.postId === payload.data.postId
          );
          const res = [...reply.replies];
          res[index] = payload.data;

          reply.replies = res;
        }
      }
    }
  },
  INCREMENT_NESTED_REPLY_REPLY_COUNT: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.postId);
      if (post) {
        const reply = post.replies.find((e) => e.postId === payload.replyId);

        if (reply) {
          const nestedReply = reply.replies.find(
            (e) => e.postId === payload.nestedReplyId
          );
          if (nestedReply) nestedReply.replyCount++;
        }
      }
    }
  },

  // Trending Indices
  ALL_TRENDING_INDICES_INIT: (state) => {
    state.allTrendingIndicesLoading = false;
    state.allTrendingIndices = [];
    state.allTrendingIndicesError = null;
  },
  SET_ALL_TRENDING_INDICES_LOADING: (state, isLoading) => {
    state.allTrendingIndicesLoading = isLoading;
  },
  SET_ALL_TRENDING_INDICES_ERROR: (state, err) => {
    state.allTrendingIndicesError = err;
  },
  SET_ALL_TRENDING_INDICES: (state, payload) => {
    state.allTrendingIndices = payload;
  },

  // Post deletion
  DELETE_USER_POST_REPLY_SUCCESS: (state, payload) => {
    if (state.feed) {
      const postIndex = state.feed.findIndex(
        (p) => p.postId === payload.postId
      );

      if (postIndex !== -1) {
        const replyIndex = state.feed[postIndex].replies.findIndex(
          (r) => r.postId === payload.replyId
        );

        if (replyIndex !== -1) {
          // Decrementing reply count for the post
          state.feed[postIndex].replyCount--;
          state.feed[postIndex].replies.splice(replyIndex, 1);
        }
      }
    }
  },
  DELETE_USER_POST_REPLY_ERROR: (state, error) => {
    state.deleteUserPost.error = error;
  },
  DELETE_POST_NESTED_REPLY_SUCCESS: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((p) => p.postId === payload.postId);

      if (post) {
        const reply = post.replies.find((r) => r.postId === payload.replyId);

        if (reply) {
          const nestedReplyIndex = reply.replies.findIndex(
            (nr) => nr.postId === payload.nestedReplyId
          );

          if (nestedReplyIndex !== -1) {
            // Decrementing reply count for the post
            reply.replyCount--;
            reply.replies.splice(nestedReplyIndex, 1);
          }
        }
      }
    }
  },
  DELETE_POST_NESTED_REPLY_ERROR: (state, error) => {
    state.deleteUserPost.error = error;
  },
};
