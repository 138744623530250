import { showErrorToast, showToast } from '~/utils/toast-notifications';

import { ActionsCreator } from '@/store/utils';

// Constants
const followerLimit = 21;
const followedLimit = 21;

export default {
  initProfilePage(context) {
    context.commit('INIT_PROFILE');
  },
  getProfile(context, payload) {
    const { noSkeletons, profileId } = payload;

    // NOTE: With this in place, we only show skeletons if we don't explicitly specify that we don't want skeletons,
    // AND the related data hasn't ALREADY been loaded.
    // This is to prevent unnecessary skeleton showing if the data has already been loaded.
    // Instead, we simply replace the existing data and the UI updates accordingly.
    if (!noSkeletons && context.state.profile === null)
      context.commit('SET_PROFILE_LOADING', true);

    return this.dispatch('api/getProfileById', profileId)
      .then((res) => {
        context.commit('SET_PROFILE', res.data);
      })
      .catch((err) => {
        context.commit('SET_PROFILE_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_PROFILE_LOADING', false);
      });
  },

  setProfile(context, profile) {
    context.commit('INIT_PROFILE');
    context.commit('SET_PROFILE', profile);
  },
  setContentStep(context, step) {
    context.commit('SET_CONTENT_STEP', step);
  },
  favoritePost(context, payload) {
    this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_POST_FAVORITE', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  followProfile(context, payload) {
    this.dispatch('api/followProfile', payload)
      .then((res) => {
        context.commit('SET_PROFILE', res.data);
      })
      .catch((err) => {
        context.commit('SET_PROFILE_ERROR', err);
      });
  },
  setProfileFollow(context, payload) {
    context.commit('SET_PROFILE_FOLLOW', payload);
  },
  toggleProfileEditModal(context) {
    context.commit('TOGGLE_PROFILE_EDIT_MODAL');
  },
  setWTPmodalSeen(context) {
    this.dispatch('api/setWTPmodalSeen').then(() => {
      context.commit('SET_WTPMODAL_SEEN');
    });
  },
  // Profile feed
  likePost(context, payload) {
    this.dispatch('api/likePost', payload)
      .then((res) => {
        context.commit('UPDATE_POST_LIKE', res.data);
      })
      .catch((err) => {
        context.commit('SET_FEED_ERROR', err);
      });
  },
  incrementReplyCount(context, payload) {
    context.commit('INCREMENT_REPLYCOUNT', payload.postId);
  },
  incrementRepostCount(context, payload) {
    context.commit('INCREMENT_REPOSTCOUNT', payload.postId);
  },
  getFeed(context, payload) {
    const { noSkeletons } = payload ?? {};

    const apiParams = { ...payload };
    delete apiParams.noSkeletons;

    if (!noSkeletons) context.commit('SET_FEED_LOADING', true);

    return this.dispatch('api/getProfilePosts', apiParams)
      .then((res) => {
        context.commit('SET_FEED', res.data);
      })
      .catch((err) => {
        console.dir(err);
        context.commit('SET_FEED_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_FEED_LOADING', false);
      });
  },
  getNextPosts(context, payload) {
    context.commit('SET_NEXT_POST_LOADING', true);
    return this.dispatch('api/getProfilePosts', payload)
      .then((res) => {
        context.commit('APPEND_POSTS_TO_FEED', res.data);
        return res;
      })
      .catch((err) => {
        console.dir(err);
        context.commit('SET_FEED_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_POST_LOADING', false);
      });
  },
  appendNewPostAtTop(context, post) {
    context.commit('APPEND_NEW_POST_AT_TOP', post);
  },
  updatePostAfterEdit(context, post) {
    context.commit('UPDATE_POST', post);
  },
  // Profile Followers
  getFollowers(context, profileId) {
    context.commit('SET_FOLLOWERS', null);
    context.commit('RESET_FOLLOWERS_PAGE');
    context.commit('SET_FOLLOWERS_LOADING', true);
    return this.dispatch('api/getProfileFollowers', {
      profileId: profileId,
      limit: followerLimit,
      page: 1,
    })
      .then((res) => {
        context.commit(
          'SET_SHOW_LOAD_MORE_FOLLOWERS',
          res.data.length == followerLimit
        );

        context.commit('SET_FOLLOWERS', res.data);
      })
      .catch((err) => {
        // TODO
        console.log('error: ', err);
      })
      .finally(() => {
        context.commit('SET_FOLLOWERS_LOADING', false);
      });
  },
  getNextFollowers(context, profileId) {
    context.commit('SET_NEXT_FOLLOWERS_LOADING', true);
    return this.dispatch('api/getProfileFollowers', {
      profileId: profileId,
      limit: followerLimit,
      page: context.state.followersPage + 1,
    })
      .then((res) => {
        context.commit(
          'SET_SHOW_LOAD_MORE_FOLLOWERS',
          res.data.length == followerLimit
        );
        context.commit('INCREASE_FOLLOWERS_PAGE');
        context.commit('APPEND_FOLLOWERS', res.data);
      })
      .catch((err) => {
        // TODO
        console.log('error: ', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_FOLLOWERS_LOADING', false);
      });
  },
  followFollower(context, profileId) {
    return this.dispatch('api/followProfile', profileId)
      .then((res) => {
        context.commit('UPDATE_FOLLOWER_IS_FOLLOWED', res.data);
      })
      .catch((err) => {
        // TODO
        console.log('Error:', err);
      })
      .finally(() => {
        console.log('TODO');
      });
  },
  deleteUserPost(context, payload) {
    return this.dispatch('api/deleteUserPost', payload)
      .then((res) => {
        context.commit('DELETE_USER_POST_SUCCESS', res.data);
        showToast(window.$nuxt.$t('post_deletion_successful'));
      })
      .catch((err) => {
        context.commit('DELETE_USER_POST_ERROR', err);
        showErrorToast(window.$nuxt.$t('error_occured_message'));
      });
  },
  deletePostReply(context, payload) {
    return this.dispatch('api/deleteUserPost', { postId: payload.replyId })
      .then((res) => {
        context.commit('DELETE_POST_REPLY_SUCCESS', {
          postId: payload.postId,
          replyId: res.data,
        });
        showToast(window.$nuxt.$t('post_deletion_successful'));
      })
      .catch((err) => {
        context.commit('DELETE_POST_REPLY_ERROR', err);
        showErrorToast(window.$nuxt.$t('error_occured_message'));
      });
  },
  deletePostNestedReply(context, payload) {
    return this.dispatch('api/deleteUserPost', {
      postId: payload.nestedReplyId,
    })
      .then((res) => {
        context.commit('DELETE_POST_NESTED_REPLY_SUCCESS', {
          postId: payload.postId,
          replyId: payload.replyId,
          nestedReplyId: res.data,
        });
        showToast(window.$nuxt.$t('post_deletion_successful'));
      })
      .catch((err) => {
        context.commit('DELETE_POST_NESTED_REPLY_ERROR', err);
        showErrorToast(window.$nuxt.$t('error_occured_message'));
      });
  },
  // Profile Followed
  getFollowed(context, payload) {
    context.commit('SET_FOLLOWED', null);
    context.commit('RESET_FOLLOWED_PAGE');
    context.commit('SET_FOLLOWED_LOADING', true);
    return this.dispatch('api/getProfileFollowed', {
      profileId: payload.profileId,
      profileType: payload.profileType,
      stockType: payload.stockType,
      limit: followedLimit,
      page: 1,
    })
      .then((res) => {
        context.commit(
          'SET_SHOW_LOAD_MORE_FOLLOWED',
          res.data.length == followedLimit
        );
        context.commit('SET_FOLLOWED', res.data);
      })
      .catch((err) => {
        // TODO
        console.log('error: ', err);
      })
      .finally(() => {
        context.commit('SET_FOLLOWED_LOADING', false);
      });
  },
  getNextFollowed(context, payload) {
    context.commit('SET_NEXT_FOLLOWERS_LOADING', true);
    return this.dispatch('api/getProfileFollowed', {
      profileId: payload.profileId,
      profileType: payload.profileType,
      stockType: payload.stockType,
      limit: followedLimit,
      page: context.state.followedPage + 1,
    })
      .then((res) => {
        context.commit(
          'SET_SHOW_LOAD_MORE_FOLLOWED',
          res.data.length == followedLimit
        );
        context.commit('INCREASE_FOLLOWED_PAGE');
        context.commit('APPEND_FOLLOWED', res.data);
      })
      .catch((err) => {
        // TODO
        console.log('error: ', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_FOLLOWED_LOADING', false);
      });
  },
  followFollowed(context, profileId) {
    return this.dispatch('api/followProfile', profileId)
      .then((res) => {
        context.commit('UPDATE_FOLLOWED_IS_FOLLOWED', res.data);
      })
      .catch((err) => {
        // TODO
        console.log('Error: ', err);
      })
      .finally(() => {
        console.log('TODO');
      });
  },
  rateProfile(context, profileId) {
    return this.dispatch('api/rateProfile', profileId)
      .then((res) => {
        context.commit('SET_AVERAGE_RATING', res.data.averageRating);
        context.commit('SET_RATING_COUNT', res.data.ratingCount);
      })
      .catch((err) => {
        // TODO
        console.log('error: ', err);
      })
      .finally(() => {
        console.log('TODO');
      });
  },
  getProfileFollowedCountPerType(context, payload) {
    context.commit('SET_FOLLOWED_PROFILES_COUNT_LOADING', true);
    return this.dispatch('api/getProfileFollowedCountPerType', payload)
      .then((res) => {
        context.commit('SET_FOLLOWED_PROFILES_COUNT', res.data);
      })
      .catch((err) => {
        context.commit('SET_FOLLOWED_PROFILES_COUNT_ERROR', err);
        console.error(err);
      })
      .finally(() => {
        context.commit('SET_FOLLOWED_PROFILES_COUNT_LOADING', false);
      });
  },
  /// Post Replies
  postComment(context, payload) {
    return this.dispatch('api/createPost', payload)
      .then((res) => {
        showToast(window.$nuxt.$t('post_created_ok'));
        context.commit('APPEND_NEW_REPLY', res.data);
      })
      .catch((err) => {
        showErrorToast(window.$nuxt.$t('error_occured_message'));
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.target,
        });
      });
  },
  likeReply(context, payload) {
    this.dispatch('api/likePost', payload.replyId)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.replyId,
        });
      });
  },
  favoriteReply(context, payload) {
    this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  incrementReplyReplyCount(context, payload) {
    if (payload && payload.postId) {
      context.commit('INCREMENT_REPLY_REPLY_COUNT', payload);
    }
  },
  /// Nested Replies
  loadNestedReplies(context, payload) {
    return this.dispatch('api/getPostReplies', {
      postId: payload.replyId,
      params: payload.params,
    })
      .then((res) => {
        context.commit('SET_NESTED_REPLIES', {
          replies: res.data,
          postId: payload.postId,
          replyId: payload.replyId,
        });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  loadNextNestedReplies(context, payload) {
    return this.dispatch('api/getPostReplies', {
      postId: payload.replyId,
      params: payload.params,
    })
      .then((res) => {
        context.commit('APPEND_NESTED_REPLY_BOTTOM', {
          replies: res.data,
          postId: payload.postId,
          replyId: payload.replyId,
        });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  appendNestedReplyTop(context, payload) {
    context.commit('APPEND_NESTED_REPLY_TOP', payload);
  },
  favoriteNestedReply(context, payload) {
    return this.dispatch('api/favoritePost', payload.nestedReplyId)
      .then((res) => {
        context.commit('UPDATE_NESTED_REPLY', {
          data: res.data,
          postId: payload.postId,
        });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  likeNestedReply(context, payload) {
    return this.dispatch('api/likePost', payload.nestedReplyId)
      .then((res) => {
        context.commit('UPDATE_NESTED_REPLY', {
          data: res.data,
          postId: payload.postId,
        });
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.replyId,
        });
      });
  },
  incrementNestedReplyReplyCount(context, payload) {
    context.commit('INCREMENT_NESTED_REPLY_REPLY_COUNT', payload);
  },
  deleteProfile(context) {
    context.commit('DELETE_PROFILE_INIT');
    return this.dispatch('api/deleteProfile')
      .then(() => {
        context.commit('DELETE_PROFILE_SUCCESS');
      })
      .catch((err) => {
        context.commit('DELETE_PROFILE_ERROR', {
          err: err,
        });
        showErrorToast(window.$nuxt.$t('oops_error'));
        throw Error(err);
      });
  },

  reportProfile(context, payload) {
    return this.dispatch('api/reportProfile', payload).then((res) => {
      if (res.data) context.commit('UPDATE_REPORT_LIST', res);
    });
  },

  getReports(context) {
    return this.dispatch('api/getReports').then((res) => {
      context.commit('ADD_TO_REPORT_LIST', res);
    });
  },

  updateBlocks(context, payload) {
    context.commit('UPDATE_BLOCK_LIST', payload);
  },

  removeBlocks(context, payload) {
    context.commit('REMOVE_FROM_BLOCK_LIST', payload);
  },

  getBlocks(context) {
    return this.dispatch('api/getBlocks').then((res) => {
      context.commit('ADD_TO_BLOCK_LIST', res);
    });
  },

  // Outdated
  // Todo : clean below
  ...ActionsCreator([
    {
      name: 'getProfileById',
      mutation: 'GET_PROFILE_BY_ID',
    },
    {
      name: 'createProfile',
      mutation: 'CREATE_PROFILE',
    },
    {
      name: 'validateProfileProfilename',
      mutation: 'VALIDATE_PROFILE_USERNAME',
    },
    {
      name: 'followedIdustries',
      mutation: 'GET_FOLLOWED_INDUSTRIES',
    },
  ]),
};
